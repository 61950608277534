import { useCallback, useState } from 'react';
import { applicationData } from '@src/util/environment';

export default function useRecaptchaToken({ action }) {
  const [token, setToken] = useState("");

  const execute = useCallback(() => {
    return new Promise((resolve) => {
      if (!applicationData.reCAPTCHA) {
        resolve("");
      }

      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(applicationData.reCAPTCHA, { action });
        setToken(token);
        setTimeout(() => resolve(token), 30);
      });
    });
  }, [action]);

  return { token, action, execute };
}
