import type { ReactNode } from 'react';

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import HolidayVillageTwoToneIcon from '@mui/icons-material/HolidayVillageTwoTone';
import MarkAsUnreadTwoToneIcon from '@mui/icons-material/MarkAsUnreadTwoTone';
import ScheduleSendTwoToneIcon from '@mui/icons-material/ScheduleSendTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import EditNoteTwoToneIcon from '@mui/icons-material/EditNoteTwoTone';
import ReportTwoToneIcon from '@mui/icons-material/ReportTwoTone';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
  role?: string;
  scope?: string;
}

interface Roles {
  rolePages: { name: string, role: string }[],
}

const ROLES_LIST = {
  housekeepingManagement: {
    recruitments: "housekeeping_shift_recruitments",
    requests: "housekeeping_shift_requests",
    assignments: "housekeeping_shift_assignments",
    confirmations: "housekeeping_shift_confirmations",
    patterns: "housekeeping_shift_patterns",
    roomAssignments: "housekeeping_room_assignments",
    executions: "housekeeping_room_executions",
  },
  reservations: {
    reservations: "reservations",
  },
  properties: {
    properties: "properties",
  },
  messages: {
    messageDeliveryLogs: "message_delivery_logs",
  },
  top: {
    top: "top",
  }
}

const getRole = (name: string, roles: Roles) => {
  const role = roles?.rolePages?.find(items => items.name === name);
  if (role) return role.role;
  else return "no"
}

const isShowHeader = (header: string, roles: Roles) => {
  let flag = false;
  for (let key in ROLES_LIST[header]) {
    const role = roles?.rolePages?.find(items => items.name === ROLES_LIST[header][key]);
    if (role) {
      flag = true;
      break;
    }
  }
  return flag
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
  isShowHeader?: boolean;
}

const getMenuItems = (roles: Roles) => {
  const menuItems: MenuItems[] = [
    {
      heading: 'Dashboard',
      isShowHeader: isShowHeader("top", roles),
      items: [
        {
          name: 'Home',
          icon: DashboardTwoToneIcon,
          link: '/',
          role: getRole("top", roles),
        },
      ],
    },
    {
      heading: 'Reservation Management',
      isShowHeader: isShowHeader("reservations", roles),
      items: [
        {
          name: 'Reservations',
          icon: MenuBookTwoToneIcon,
          link: '/reservations',
          role: getRole("reservations", roles),
          items: [
            {
              name: 'All Reservations',
              link: '/reservations',
              role: getRole("reservations", roles),
            },
            {
              name: 'Staying Reservations',
              link: '/reservations/staying',
              role: getRole("reservations", roles),
            },
            {
              name: 'Unsent Reservations',
              icon: ReportTwoToneIcon,
              link: '/reservations/unsent',
              role: getRole("reservations", roles),
            },
          ],
        },
      ]
    },
    {
      heading: 'Property Management',
      isShowHeader: isShowHeader("properties", roles),
      items: [
        {
          name: 'Properties',
          icon: HolidayVillageTwoToneIcon,
          link: '/properties',
          role: getRole("properties", roles),
        },
      ]
    },
    {
      heading: 'Housekeeping Management',
      isShowHeader: isShowHeader("housekeepingManagement", roles),
      items: [
        {
          name: 'Housekeeping Management',
          icon: CleaningServicesIcon,
          items: [
            {
              name: 'Shift Recruitment',
              link: '/housekeeping/shift/recruitments',
              role: getRole("housekeeping_shift_recruitments", roles),
            },
            {
              name: 'Shift Request',
              link: '/housekeeping/shift/requests',
              role: getRole("housekeeping_shift_requests", roles),
            },
            {
              name: 'Shift Assignment',
              link: '/housekeeping/shift/assignments',
              role: getRole("housekeeping_shift_assignments", roles),
            },
            {
              name: 'Shift Confirmation',
              link: '/housekeeping/shift/confirmations',
              role: getRole("housekeeping_shift_confirmations", roles),
            },
            {
              name: 'Shift Pattern',
              link: '/housekeeping/shift/patterns',
              role: getRole("housekeeping_shift_patterns", roles),
            },
            {
              name: 'Room Assignment',
              link: '/housekeeping/room/assignments',
              role: getRole("housekeeping_room_assignments", roles),
            },
            {
              name: 'Cleaning Execution',
              link: '/housekeeping/executions',
              role: getRole("housekeeping_room_executions", roles),
            },
          ],
        },
      ]
    },
    {
      heading: 'Contents Management',
      isShowHeader: isShowHeader("messages", roles),
      items: [
        {
          name: 'Message Management',
          icon: EmailTwoToneIcon,
          scope: '/message/',
          role: getRole("message_delivery_logs", roles),
          link: '/message/delivery/logs',
          items: [
            {
              name: 'Message Delivery Logs',
              icon: MarkAsUnreadTwoToneIcon,
              link: '/message/delivery/logs',
              role: getRole("message_delivery_logs", roles),
            },
            {
              name: 'Message Templates',
              icon: EditNoteTwoToneIcon,
              link: '/message/templates',
              role: getRole("message_delivery_logs", roles),
            },
            {
              name: 'Message Schedule',
              icon: ScheduleSendTwoToneIcon,
              link: '/message/schedules',
              role: getRole("message_delivery_logs", roles),
            },
          ],
        },
      ]
    },
      // {
  //   heading: 'Management',
  //   items: [
  //     {
  //       name: 'Users',
  //       icon: AssignmentIndTwoToneIcon,
  //       link: '/extended-sidebar/management/users',
  //       items: [
  //         {
  //           name: 'List',
  //           link: 'management/users/list'
  //         },
  //         {
  //           name: 'User Profile',
  //           link: 'management/users/single'
  //         }
  //       ]
  //     },
  //     {
  //       name: 'Projects',
  //       icon: AccountTreeTwoToneIcon,
  //       link: '/extended-sidebar/management/projects/list'
  //     },
  //     {
  //       name: 'Commerce',
  //       icon: StorefrontTwoToneIcon,
  //       link: '/extended-sidebar/management/commerce',
  //       items: [
  //         {
  //           name: 'Shop',
  //           link: 'management/commerce/shop'
  //         },
  //       ]
  //     },
  //     {
  //       name: 'Invoices',
  //       icon: ReceiptTwoToneIcon,
  //       link: '/extended-sidebar/management/invoices',
  //       items: [
  //         {
  //           name: 'List',
  //           link: 'management/invoices/list'
  //         },
  //       ]
  //     }
  //   ]
  // },
  ];
  return menuItems
}

export default getMenuItems;
