import { createContext, useContext } from 'react';
import { applicationData } from '@src/util/environment';
import { useAPI } from '@src/util/api';
import User from '@src/models/User';

const Context = createContext({});

const refreshInterval = 1000 * 60 * 30;

export const AuthProvider: React.FC = props => {
  const { user: fallbackData } = applicationData;
  const { data: user, error, ...rest } = useAPI(
    '/v1/pms/users/me',
    { refreshInterval, fallbackData, model: User }
  );

  if (error?.status == 404) {
    location.href = '/users/sign-out';
  }

  return <Context.Provider value={{ user, ...rest }} {...props} />;
};

export const useAuth = () => useContext(Context);

